<template>
  <div>
    <loading :active.sync="isLoading" :enforce-focus="false"></loading>
    <b-modal :id="modal_id" title="マスタ > 顧客" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <!-- 一覧 -->
      <div>
        <div style="width: 1200px; ">
          <b-table-filter-pager :busy="busy" :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :filter_val_ex="filter_val_ex" :data_list_field="data_list_field" :data_list="data_list"
            :data_count="data_count" :currentPage="current_page" @on_sort="onSort" @on_edit='onDetail'
            @on_delete='onDelete' @on_filter_ex='onFilterEx' @on_add='modalShow' @on_page_change='onPageChange'
            :filter_enable="true" :nav_flg="true" :edit_disabled="pms_master_client_company < 2" />
        </div>
      </div>
      <div style="width: 280px; margin: -45px 0 0 920px">
        <b-button v-if="!export_flg" @click="CsvExport" variant="primary"
          style="width: 135px !important; padding: 8px 0px;">
          <b-icon icon="file-earmark-arrow-up"></b-icon>&nbsp;エクスポート
        </b-button>
        <b-button v-if="export_flg" variant="primary" disabled style=" width:135px !important; padding: 8px 0px;">
          <b-spinner small></b-spinner>
          Loading...
        </b-button>
        <b-button @click="showCsvImport" variant="info" style="width: 130px !important; padding: 8px 0px;" :disabled="pms_master_client_company < 2">
          <b-icon icon="file-earmark-arrow-down"></b-icon>&nbsp;インポート
        </b-button>
      </div>
      <!-- モーダル 会社詳細 -->
      <b-modal id="modal-client_company" title="顧客 > 追加" :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <label>顧客コード</label>
            <b-input v-model="client_company.company_code" type="text" />
          </div>
          <div class="form-inline">
            <label>顧客名</label>
            <b-input v-model="client_company.company_name" type="text" style="width: 300px" />
          </div>
          <div class="form-inline">
            <label>担当者</label>
            <b-form-select v-model="client_company.user_id" style="width: 200px">
              <option v-for="option in sel_user_list" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div class="modal-footer">
          <b-button @click="$bvModal.hide('modal-client_company')">
            <b-icon icon="x"></b-icon>取消
          </b-button>
          <b-button variant="primary" @click="updateClientCompany">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
        </div>
      </b-modal>
    </b-modal>
    <!-- 詳細 モーダル -->
    <MasterClientCompanyDetail ref="child_master_client_company_detail" @close_modal='closeDetailModal'
      :modal_id="'master_client_company-master_client_company_detail'" :top_title="'マスタ > 顧客 > 編集'"/>
    <!-- CSVインポート -->
    <CsvImport ref="csv_import" :modal_id="'client-csv_import'" @close_modal='getClientCompanyList' />
  </div>
</template>

<script>
import axios from 'axios';
const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      // table
      isLoading: false,
      current_page: 1,
      busy: false,
      sort_by: '',
      sort_desc: true,
      where: "",
      filter_list: new Array(15),
      filter_val: new Array(15),
      filter_val_ex: new Array(15),
      data_list: [],
      data_count: 0,
      data_list_field: [
        { key: "edit", label: "編集", thStyle: { width: "60px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, edit2: true },
        { key: "company_code", label: "顧客コード", thStyle: { width: "120px" }, sortable: true, filter: 'input_ex' },
        { key: "company_name", label: "顧客名", thStyle: { width: "200px" }, sortable: true, filter: 'input_ex' },
        { key: "company_name_kana", label: "顧客名(ひらがな)", thStyle: { width: "200px" }, sortable: true, filter: 'input_ex' },
        { key: "zip_code", label: "郵便番号", thStyle: { width: "100px" }, sortable: true, filter: 'input_ex' },
        { key: "address", label: "住所", thStyle: { width: "350px" }, sortable: true, filter: 'input_ex' },
        { key: "user", label: "弊社担当", thStyle: { width: "" }, sortable: true, filter: 'select_ex2' },
      ],
      //
      export_flg: false,
      //modal
      sel_user_list: [],
      //
      msg: 'msg',
      client_company: [],
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 270 + "px"
    }
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    closeDetailModal: function () {
      this.getClientCompanyList();
    },
    //
    onFilterEx: function () {
      this.current_page = 1;
      this.getClientCompanyList(1);
    },
    onPageChange: function (current_page) {
      this.current_page = current_page;
      this.getClientCompanyList();
    },
    // 顧客 - 会社　詳細
    onDetail: function (client_company_id) {
      this.$refs.child_master_client_company_detail.setClientCompanyId(client_company_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'master_client_company-master_client_company_detail');
      }, 100);
    },
    // 顧客 - 会社一覧 取得
    getClientCompanyList: function () {
      this.isLoading = true
      axios
        .get('/master_client_company/get_client_company_list', {
          params: {
            company_code_ex: this.filter_val_ex[1],
            company_name_ex: this.filter_val_ex[2],
            company_name_kana_ex: this.filter_val_ex[3],
            zip_code_ex: this.filter_val_ex[4],
            address_ex: this.filter_val_ex[5],
            user_id_ex: this.filter_val_ex[6],
            sort_by: this.sort_by, sort_desc: this.sort_desc,
            current_page: this.current_page
          }
        })
        .then(response => {
          this.data_list = response.data.client_company_list;
          this.data_count = response.data.client_company_count;
          this.filter_list[6] = response.data.user_filter_list_ex;
          this.filter_val_ex[6] = response.data.user_id_ex;
          this.where = response.data.where;
          this.isLoading = false;
        })
    },
    // 顧客 - 会社 削除
    onDelete: function (client_company_id) {
      if (confirm('削除しますか？')) {
        axios
          .delete(`/master_client_company/del_client_company/${client_company_id}`)
          .then(response => {
            (this.client_companies = response.data);
          })
      }
    },
    // 顧客 - 会社-モーダル(新規) 表示
    modalShow: function (client_company_id) {
      axios
        .get(`/master_client_company/get_client_company/${client_company_id}`)
        .then(response => {
          this.client_company = response.data.client_company_h;
          this.sel_user_list = response.data.sel_user_list;
          this.$root.$emit('bv::show::modal', 'modal-client_company')
        })
    },
    // 顧客 - 会社 新規登録
    updateClientCompany: function () {
      axios
        .patch(`/master_client_company/update_client_company`, { client_company: this.client_company })
        .then(response => {
          (this.client_company = response.data);
          this.getClientCompanyList();
          this.$root.$emit('bv::hide::modal', 'modal-client_company')
        })
    },
    // 郵便番号検索
    searchAddress: function () {
      const zipCode = this.client_company.zip_code;
      axios
        .get(`https://api.zipaddress.net/?zipcode=${zipCode}`, { adapter: jsonpAdapter })
        .then(rs => {
          const response = rs.data;
          if (response.code == '400') {
            alert('郵便番号は数値7桁で入力してください');
          } else {
            this.client_company.address1 = response.pref + response.city + response.town
          }
        })
    },
    // Import
    showCsvImport: function () {
      this.$refs.csv_import.setImportType('client_company');
      this.$root.$emit('bv::show::modal', 'client-csv_import');
    },
    // Export
    CsvExport: function () {
      if (this.data_count < 100001) {
        this.export_flg = true;
        axios
          .patch(`/master_item/csv_export`, { where: this.where, master_name: 'client_company' })
          .then(response => {
            //リンク先にダウンロード用リンクを指定する
            const link = document.createElement('a')
            link.download = response.data.file_name
            link.href = response.data.file_dir + response.data.file_name
            link.click()
            this.export_flg = false;
          }).catch((error) => {
            console.error(error, error.response);
            this.export_flg = false;
          });
      } else {
        alert("件数を10000以内にように絞り込みをしてください")
      }
    },
    // ソート
    onSort: function (ctx) {
      this.sort_by = ctx.sortBy;
      this.sort_desc = ctx.sortDesc;
      this.getClientCompanyList();
    }
  },
}
</script>

<style scoped></style>