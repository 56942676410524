<template>
  <div>
    <!-- ================================================================== -->
    <!-- Header -->
    <!-- ================================================================== -->
    <Header ref="header" @close_modal="closeModal"></Header>
    <loading :active.sync="isLoading" :enforce-focus="false"></loading>
    <!-- ================================================================== -->
    <!-- 見積 -->
    <!-- ================================================================== -->
    <div style="margin: 10px 20px;">
      <div style="margin-bottom: 5px;" class="form-inline">
        <b-form-select v-model="on_type" style="width: 100px" @change="onDateChange">
          <option v-for="option in sel_on_type" v-bind:value="option.value" :key="option.id">
            {{ option.text }}
          </option>
        </b-form-select>
        <v-date-picker v-model="date1" @input="onDateChange" class="date_picker" >
          <template v-slot="{ inputValue, inputEvents }">
            <b-input :value="inputValue" v-on="inputEvents" readonly />
          </template>
        </v-date-picker>
        &nbsp;-&nbsp;
        <v-date-picker v-model="date2" @input="onDateChange" class="date_picker" is-required>
          <template v-slot="{ inputValue, inputEvents }">
            <b-input :value="inputValue" v-on="inputEvents" readonly />
          </template>
        </v-date-picker>
        &nbsp;&nbsp;
        <b-button v-if="mitumori_sel_load" variant="info" disabled style="width:140px !important;">
          <b-spinner small></b-spinner>
          Loading...
        </b-button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        受注：<font color="#ff0000">{{ jyutyu_x_cnt }}</font>
        &nbsp;&nbsp;
        納品：<font color="#ff0000">{{ nouhin_x_cnt }}</font>（<font color="#ff7f50">{{ nouhin_enb_cnt }}</font>)
        &nbsp;&nbsp;
        請求：<font color="#ff0000">{{ seikyu_x_cnt }}</font>
        &nbsp;&nbsp;
        入金：<font color="#ff0000">{{ nyukin_x_cnt }}</font>
      </div>
      <!-- 一覧 -->
      <div :style="'width: ' + maxWidth + '; overflow:auto;'">
        <div style="width: 1250; ">
          <b-table-filter-pager :busy="mitumori_load" :maxHeight="maxHeight" :filter_list="filter_list"
            :filter_val="filter_val" :filter_val_ex="filter_val_ex" :data_count="data_count"
            :tooltip_plus="{ title: '見積書の新規追加' }" :currentPage="current_page" :data_list_field="data_list_field"
            :data_list="data_list" @on_sort="onSort" @on_page_change='onPageChange' @on_edit='onDetail'
            @on_filter_ex='onFilterEx' @jyutyu_show="jyutyuShow" @nouhin_show="nouhinShow" @seikyu_show="seikyuShow"
            @nyukin_show="nyukinShow" @on_add='modalShow' :nav_flg="true" :filter_enable="true" />
          <div class="form-inline" style=" margin-top: -45px; ">
            <label style="margin-left: 1040px; ">合計</label>
            <b-input v-model="price_total" type="text" style="width: 140px; text-align: right;" disabled />
          </div>
        </div>
      </div>
      <!-- モーダル -->
      <b-modal id="modal-mitumori" title="見積 > 追加" :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <label style="">見積日</label>
            <v-date-picker v-model="mitumori.mitumori_on" class="date_picker">
              <template v-slot="{ inputValue, inputEvents }">
                <b-input :value="inputValue" v-on="inputEvents" :readonly="true" />
              </template>
            </v-date-picker>
          </div>
          <div class="form-inline">
            <label>品題</label>
            <b-input v-model="mitumori.title" type="text" style="width: 350px" />
          </div>
          <div class="form-inline">
            <label>品類</label>
            <b-input v-model="mitumori.teiki_type1" type="text" style="width: 200px" />
          </div>
          <div class="form-inline">
            <label>品目</label>
            <b-input v-model="mitumori.teiki_type2" type="text" style="width: 200px" />
          </div>
          <div class="form-inline">
            <SelectClientCompany ref="select_client_company_ref" @on_select='onClientCompanySelect' />
            <label>顧客</label>
            <b-input v-model="mitumori.client_company_name" type="text" style="width:200px; background-color: white;"
              @click="selectClientCompanyModalShow" readonly />
            <b-button v-if="!client_loading" @click="selectClientCompanyModalShow" variant="info" class="icon_btn">
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
            <b-button v-if="client_loading" variant="info" class="icon_btn" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </b-button>
          </div>
          <div class="form-inline">
            <label>顧客担当者</label>
            <b-form-select v-model="mitumori.client_person_id" style="width: 200px">
              <option></option>
              <option v-for="option in sel_client_person" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </div>
          <div class="form-inline">
            <label>担当者</label>
            <b-form-select v-model="mitumori.user_id" style="width: 200px">
              <option v-for="option in sel_person" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div class="modal-footer">
          <b-button @click="$bvModal.hide('modal-mitumori')">
            <b-icon icon="x"></b-icon>取消
          </b-button>
          <b-button variant="primary" @click.once="createMitumori">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data: function () {
    return {
      isLoading: false,
      // Header
      mitumori_load: false,
      mitumori_sel_load: false,
      on_type: 0,
      sel_on_type: [{ value: 0, text: "見積日" }, { value: 1, text: "受注日" },
      { value: 2, text: "納品日" }, { value: 3, text: "請求日" }],
      date1: '',
      date2: '',
      user: [],
      jyutyu_x_cnt: 0,
      nouhin_x_cnt: 0,
      nouhin_enb_cnt: 0,
      seikyu_x_cnt: 0,
      nyukin_x_cnt: 0,
      // table
      current_page: 1,
      busy: false,
      sort_by: '',
      sort_desc: true,
      price_total: 0,
      mitumori_cnt: 0,
      filter_list: new Array(15),
      filter_val: new Array(15),
      filter_val_ex: new Array(15),
      data_count: 0,
      data_list: [],
      data_list_field: [
        { key: "edit", label: "見積", thStyle: { width: "60px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, edit: true },
        { key: "jyutyu_status", label: "受注", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: 'select_sts_ex' },
        { key: "nouhin_status", label: "納品", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: 'select_sts_ex' },
        { key: "seikyu_status", label: "請求", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: 'select_sts_ex' },
        { key: "nyukin_status", label: "入金", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: 'select_sts_ex' },
        { key: "m_code", label: "伝票番号", thStyle: { width: "100px" }, sortable: true, filter: 'input_ex' },
        { key: "title", label: "品題", thStyle: { width: "150px" }, sortable: true, filter: 'input_ex' },
        { key: "teiki_type1", label: "品類", thStyle: { width: "120px" }, sortable: true, filter: 'input_ex' },
        { key: "teiki_type2", label: "品目", thStyle: { width: "120px" }, sortable: true, filter: 'input_ex' },
        { key: "mitumori_on", label: "見積日", thStyle: { width: "140px" }, sortable: true, filter: 'input_ex' },
        { key: "client_company_name", label: "顧客", thStyle: { width: "140px" }, sortable: true, filter: 'input_ex' },
        { key: "address", label: "顧客住所", thStyle: {}, sortable: true, filter: 'input_ex' },
      ],
      // modal
      sel_person: [],
      sel_client_company: [],
      sel_client_person: [],
      mitumori: [],
      //

      //
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 230 + "px",
      maxHeight2: document.documentElement.scrollHeight - 350 + "px"
    }
  },
  async mounted() {
    await this.checkLogin();
    if (this.pms_hanbai == 0) {
      this.$router.push({ name: 'top', params: {} })
    }
    this.getMitumoriList();

  },
  methods: {
    // ===============================================================
    // 見積
    // ===============================================================
    onDateChange: function () {
      this.current_page = 1;
      this.getMitumoriList();
    },
    onPageChange: function (current_page) {
      this.current_page = current_page;
      this.getMitumoriList();
    },
    //
    onFilterEx: function () {
      this.current_page = 1;
      this.getMitumoriList();
    },
    // 見積一覧 取得
    getMitumoriList: function () {
      this.mitumori_load = false;
      this.isLoading = true;
      axios
        .get('/mitumori/get_mitumori_list', {
          params:
          {
            on_type: this.on_type, date1: this.date1, date2: this.date2,
            jyutyu_sts_ex: this.filter_val_ex[1], nouhin_sts_ex: this.filter_val_ex[2],
            seikyu_sts_ex: this.filter_val_ex[3], nyukin_sts_ex: this.filter_val_ex[4],
            m_code_ex: this.filter_val_ex[5], title_ex: this.filter_val_ex[6],
            teiki_type1_ex: this.filter_val_ex[7], teiki_type2_ex: this.filter_val_ex[8],
            mitumori_on_ex: this.filter_val_ex[9], client_company_name_ex: this.filter_val_ex[10],
            address_ex: this.filter_val_ex[11],
            sort_by: this.sort_by, sort_desc: this.sort_desc,
            current_page: this.current_page
          }
        })
        .then(response => {
          this.data_list_field[9]["label"] = this.sel_on_type[this.on_type]["text"]
          this.data_list = response.data.mitumori_list;
          this.price_total = response.data.price_total;
          this.jyutyu_x_cnt = response.data.jyutyu_x_cnt;
          this.nouhin_x_cnt = response.data.nouhin_x_cnt;
          this.nouhin_enb_cnt = response.data.nouhin_enb_cnt;
          this.seikyu_x_cnt = response.data.seikyu_x_cnt;
          this.nyukin_x_cnt = response.data.nyukin_x_cnt;
          this.data_count = response.data.mitumori_cnt;
          this.date1 = response.data.date1;
          this.date2 = response.data.date2;
          this.filter_list[1] = response.data.jyutyu_status_filter_list;
          this.filter_list[2] = response.data.nouhin_status_filter_list;
          this.filter_list[3] = response.data.seikyu_status_filter_list;
          this.filter_list[4] = response.data.nyukin_status_filter_list;
          this.mitumori_load = false;
          this.mitumori_cnt = this.data_list.length;
          this.isLoading = false;
        })
    },
    // 見積 詳細
    onDetail: function (mitumori_id) {
      this.$refs.header.$refs.child_mitumori_detail.setMitumori(mitumori_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'modal-mitumori_detail');
      }, 100);
    },
    // 見積 モーダル(新規、編集) 表示
    modalShow: function (mitumori_id) {
      axios
        .get(`/mitumori/get_mitumori/${mitumori_id}`)
        .then(response => {
          this.mitumori = response.data.mitumori_h;
          this.sel_client_company = response.data.sel_client_company;
          this.sel_client_person = response.data.sel_client_person;
          this.sel_person = response.data.sel_person;
          this.$root.$emit('bv::show::modal', 'modal-mitumori')
        })
    },
    // 見積 新規
    createMitumori: function () {
      if (this.processing) return;
      this.processing = true
      axios
        .patch(`/mitumori/create_mitumori`, { mitumori: this.mitumori })
        .then(response => {
          this.processing = false
          this.$root.$emit('bv::hide::modal', 'modal-mitumori')
          this.onDetail(response.data.mitumori_id)
        })
    },
    // =======================================================
    // 顧客選択モーダル表示
    selectClientCompanyModalShow: function () {
      this.client_loading = true;
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'select-client-company');
        this.client_loading = false;
      }, 50);
    },
    // 顧客選択
    onClientCompanySelect: function (item_id) {
      axios
        .get(`/mitumori/get_sel_client_person/${item_id}`)
        .then(response => {
          this.sel_client_person = response.data.sel_client_person;
          this.mitumori.client_company_id = response.data.client_company_id;
          this.mitumori.client_company_name = response.data.client_company_name;
          this.mitumori.client_person_id = "";
          this.$root.$emit('bv::hide::modal', 'select-client-company')
        })
    },
    // =======================================================
    // 受注表示
    jyutyuShow: function (mitumori_id) {
      this.$refs.header.$refs.child_jyutyu_detail.getMitumori2Jyutyu(mitumori_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'main-jyutyu_detail');
      }, 100);
    },
    // 納品詳細
    nouhinShow: function (mitumori_id) {
      this.$refs.header.$refs.child_nouhin_detail.getMitumori2Nouhin(mitumori_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'main-nouhin_detail');
      }, 100);
    },
    // 請求詳細
    seikyuShow: function (mitumori_id) {
      this.$refs.header.$refs.child_seikyu_detail.getMitumori2Nouhin(mitumori_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'main-seikyu_detail');
      }, 100);
    },
    // 入金
    nyukinShow: function (mitumori_id) {
      this.$refs.header.$refs.child_nyukin_detail.getMitumori2Nyukin(mitumori_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'main-nyukin_detail');
      }, 100);
    },
    // =======================================================
    //
    closeModal: function () {
      this.getMitumoriList();
    },
    // ソート
    onSort: function (ctx) {
      this.sort_by = ctx.sortBy;
      this.sort_desc = ctx.sortDesc;
      this.getMitumoriList();
    }
  }
}
</script>

<style scoped>
#dd:hover {
  background-color: transparent !important;
}
</style>