<template>
  <div>
    <b-table-simple bordered striped :sticky-header="maxHeight2" style="table-layout: fixed !important;">
      <b-thead head-variant="dark">
        <b-tr>
          <b-th v-for="field in data_list_field" :key="field.key" :style="field.thStyle">{{ field.label }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(data, index) in data_list" :key="data.id" :draggable="!edit_disabled" @dragstart="dragStart(index)"
          @dragenter="dragEnter(index)" @dragover.prevent @dragend="dragEnd" :class="index === dragIndex ? 'dragging' : ''" >
          <template v-for="field in data_list_field">
            <template v-if="field.key == 'edit'">
              <b-td :key="field.id" :style="field.thStyle">
                <b-button @click="onEdit(data.id)" variant="info" class="icon_btn"
                  v-bind:disabled="edit_disabled || data.edit_disabled">
                  <b-icon icon="pencil" aria-hidden="true"></b-icon>
                </b-button>
              </b-td>
            </template>
            <template v-else>
              <b-td :class="field.tdClass" :key="field.id" :style="field.thStyle">
                {{ data[field.key] }}
              </b-td>
            </template>
          </template>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import b_table_filter from './b_table_filter.vue';



export default {
  components: { b_table_filter },
  props: ['maxHeight', 'filter_list', 'filter_val', 'filter_val_ex', 'filter_val_sign', 'data_list', 'data_list_field', 'filter_enable',
    'nav_flg', 'busy', 'edit_disabled'],
  // axiosInstance,
  mounted() {
    if (this.maxHeight.substr(0, this.maxHeight.indexOf('p')) > 200) {
      this.maxHeight2 = this.maxHeight
    }

  },
  computed: {
    filterFun: function () {
      return this.filter_val
    }
  },
  data: function () {
    return {
      maxHeight2: '200px',
      dragIndex: null,
    }
  },
  methods: {
    dragStart: function (index) {
      this.dragIndex = index;

    },
    dragEnter: function (index) {
      if (index === this.dragIndex) return;
      const deleteElement = this.data_list.splice(this.dragIndex, 1)[0]
      this.data_list.splice(index, 0, deleteElement);
      this.dragIndex = index;
    },
    dragEnd: function (index) {
      this.dragIndex = null;
      this.$emit('on_drag_comp', this.data_list);
    },
    onEdit: function (id) {
      this.$emit('on_edit', id);
    }
  }
}
</script>
<style scoped>
.dragging {
  background-color: #b0c4de !important;
}

</style>