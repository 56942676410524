<template>
  <div>
    <b-modal :id="modal_id" title="インポート" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered>
      <!-- 一覧 -->
      <div>
        <div style="width: 1200px; ">
          <span v-if="master_name == 'item'">
            <div class="form-inline">
              1.発注先を選択
            </div>
            <div class="form-inline" style="">
              <b-form-select v-model="hattyu_company_sel" @change="SelHattyuCompany"
                style="width: 200px; margin-left: 10px;">
                <option v-for="option in hattyu_company_list" v-bind:value="option.value" :key="option.id">
                  {{ option.text }}
                </option>
              </b-form-select>
            </div>
            <hr />
          </span>
          <!-- CSVファイルを選択 -->
          <div v-if="master_name != 'item'" class="form-inline">
            1.CSVファイルを選択
            &nbsp;&nbsp;&nbsp;※ファイルの種類(エンコード)は「UTF-8」にして下さい
          </div>
          <div v-else class="form-inline">
            2.CSVファイルを選択
            &nbsp;&nbsp;&nbsp;※ファイルの種類(エンコード)は「UTF-8」にして下さい
          </div>
          <div style="margin-left: 20px">
            <b-form-file v-model="csv_file" :state="Boolean(csv_file)" accept="text/csv"
              placeholder="Choose a file or drop it here..." @change="upload_csv_file"
              style="width: 500px; text-align: left !important; float: left !important;"></b-form-file>
          </div>
          <br><br>
          <hr />
          <!-- ヘッダーを設定 -->
          <div v-if="master_name != 'item'" class="form-inline">
            2.ヘッダーを設定
          </div>
          <div v-else class="form-inline">
            3.ヘッダーを設定
          </div>
          <div v-if="master_name != 'item'" class="form-inline">
            <table id="header_tbl" style="margin-left: 10px;">
              <tr>
                <th v-if="master_name == 'hattyu_company'">発注コード</th>
                <th v-if="master_name == 'hattyu_company'">発注先</th>
                <th v-if="master_name == 'hattyu_company'">発注先(ひらがな)</th>
                <th v-if="master_name == 'client_company'">顧客コード</th>
                <th v-if="master_name == 'client_company'">顧客名</th>
                <th v-if="master_name == 'client_company'">顧客名(ひらがな)</th>
                <th>郵便番号</th>
                <th>住所1</th>
                <th>住所2</th>
                <th>住所3</th>
              </tr>
              <tr>
                <td v-for="n in 7" v-bind:key="n">
                  <b-form-select v-model="header_sel[n - 1]" style="width: 140px">
                    <option />
                    <option v-for="option in header_list" v-bind:value="option.value" :key="option.id">
                      {{ option.text }}
                    </option>
                  </b-form-select>
                </td>
              </tr>
            </table>
          </div>
          <div v-else class="form-inline">
            <table id="header_tbl" style="margin-left: 10px;">
              <tr>
                <th>商品コード</th>
                <th>商品分類</th>
                <th>商品名</th>
                <th>仕入れ単価</th>
                <th>単価</th>
                <th>税率</th>
                <th>メーカー</th>
              </tr>
              <tr>
                <td v-for="n in 7" v-bind:key="n">
                  <!-- 税率 -->
                  <b-form-select v-if="n == 6" v-model="header_sel[n - 1]" style="width: 140px">
                    <option />
                    <option v-for="option in header_list" v-bind:value="option.value" :key="option.id">
                      {{ option.text }}
                    </option>
                  </b-form-select>
                  <b-form-select v-if="n == 6" v-model="tax_type" style="width: 80px">
                    <option v-for="option2 in tax_type_list" v-bind:value="option2.value" :key="option2.id">
                      {{ option2.text }}
                    </option>
                  </b-form-select>
                  <!-- その他 -->
                  <b-form-select v-else v-model="header_sel[n - 1]" style="width: 140px">
                    <option />
                    <option v-for="option in header_list" v-bind:value="option.value" :key="option.id">
                      {{ option.text }}
                    </option>
                  </b-form-select>
                </td>
              </tr>
            </table>
          </div>
          <div v-if="master_name != 'item'" class="form-inline">
            <div style="margin-left: 640px">
              既に同じidがある場合は上書き保存、無い場合は新規登録されます。
            </div>
            <b-button v-if="!import_flg" @click="csvImport" variant="primary"
              style="margin-left: 935px; width: 150px !important">
              <b-icon icon="save"></b-icon>&nbsp;インポート
            </b-button>
            <b-button v-if="import_flg" variant="primary" disabled style="margin-left: 935px; width:150px !important;">
              <b-spinner small></b-spinner>
              Loading...
            </b-button>
          </div>
          <div v-else class="form-inline">
            <div style="margin-left: 640px">
              既に同じ商品コードがある場合は上書き保存、無い場合は新規登録されます。
            </div>
            <b-button v-if="!import_flg" @click="csvImport" variant="primary"
              style="margin-left: 995px; width: 150px !important">
              <b-icon icon="save"></b-icon>&nbsp;インポート
            </b-button>
            <b-button v-if="import_flg" variant="primary" disabled style="margin-left: 995px; width:150px !important;">
              <b-spinner small></b-spinner>
              Loading...
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: ['modal_id'],
  // axiosInstance,
  mounted() {
  },
  data: function () {
    return {
      master_name: "",
      csv_file: null,
      upload_file: null,
      import_flg: false,
      header_list: [],
      header_sel: [],
      hattyu_company_list: [],
      hattyu_company_sel: 0,
      tax_type: 0,
      tax_type_list: [{ text: "外税", value: 0 }, { text: "内税", value: 1 }],
      maxHeight: document.documentElement.scrollHeight - 300 + "px"
    }
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    //
    setImportType: function (masterName) {
      this.master_name = masterName;
      this.header_list = [];
      this.header_sel = [];
      this.csv_file = null;
      this.upload_file = null;
      this.csv_ini();
    },
    // 発注先選択
    SelHattyuCompany: function () {
      this.header_list = [];
      this.header_sel = [];
      this.csv_file = null;
      this.upload_file = null;
    },

    // 初期処理
    csv_ini: function (e) {
      if (this.master_name == 'item'){
      axios
        .get('/master_item/csv_ini', { params: { master_name: this.master_name } }
        ).then(response => {
          this.hattyu_company_list = response.data.hattyu_company_list;
          this.hattyu_company_sel = this.hattyu_company_list[0].value
        })
      }
    },

    // import2  ヘッダーの読み込み
    upload_csv_file: function (e) {
      const params = new FormData();
      this.upload_file = (e.target.files || e.dataTransfer.files)[0]
      params.append("master_name", this.master_name);
      params.append("hattyu_company", this.hattyu_company_sel);
      params.append("csv_file", this.upload_file);
      axios
        .post("master_item/upload_csv_file", params, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "XXXXXXXXXXXX",
          },
        })
        .then((response) => {
          this.header_list = response.data.header_list;
          this.header_sel = response.data.header_sel;
          alert("読み込みました。ヘッダーを設定してください。")
        })
        .catch((error) => {
          console.error(error, error.response);
          return;
        });
    },
    // import3 インポート
    csvImport: function () {
      const params = new FormData();
      if (this.csv_file !== null) {
        if (this.hasDuplicateValue(this.header_sel)) {
          alert("ヘッダーが重複しています")
        } else {
          this.import_flg = true;
          params.append("master_name", this.master_name);
          params.append("select_list", this.header_sel);
          params.append("hattyu_company", this.hattyu_company_sel);
          params.append("tax_type", this.tax_type);
          params.append("csv_file", this.upload_file);
          axios
            .post("master_item/csv_import", params, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "XXXXXXXXXXXX",
              },
            })
            .then((response) => {
              this.import_flg = false;
              alert(response.data.msg)
            })
            .catch((error) => {
              this.import_flg = false;
              alert("不明なエラー")
              return;
            });
        }
      } else {
        alert("ファイルを選択してください")
      }
    },
    // 配列の重複チェック
    hasDuplicateValue: function (array) {
      var newArray;
      newArray = array.filter(n => n !== "");
      const set = new Set(newArray);
      return newArray.length !== set.size;
    }
  }
}
</script>
  
<style scoped>
#header_tbl th {
  background-color: #3a6e98;
  color: white;
  border: 0.5px solid white !important;
  text-align: center;
  margin: 5px 20px;
}

#header_tbl td {
  border: 0.5px solid gray !important;
}

#header_tbl th,
#header_tbl td {
  padding: 10px 5px;
  margin: 10px;
}
</style>