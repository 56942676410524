<template>
  <div>
    <b-modal ref="select_client_company" id="select-client-company" title="顧客選択" size="xl" :header-bg-variant="'dark'"
      :header-text-variant="'light'" :body-bg-variant="'light'" @hidden="onModalHide" hide-footer centered>
      <!-- 一覧 -->
      <div>
        <div style="width: 1200px; ">
          <b-table-filter-pager :busy="busy" :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :filter_val_ex="filter_val_ex" :data_list_field="data_list_field" :data_list="data_list"
            :edit_disabled="pms_master_client_company < 2" :data_count="data_count" :currentPage="current_page"
            @on_sort="onSort" @on_page_change='onPageChange' @on_edit='onDetail' @on_add='modalShow' @on_select='onSelect'
            @on_filter_ex='onFilterEx' :filter_enable="true" :nav_flg="true" />
        </div>
      </div>
    </b-modal>
    <!-- モーダル 会社詳細 -->
    <b-modal id="modal-client_company2" title="顧客 > 追加" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" hide-footer centered>
      <div class="modal_div">
        <div class="form-inline">
          <label>顧客コード</label>
          <b-input v-model="client_company.company_code" type="text" />
        </div>
        <div class="form-inline">
          <label>顧客名</label>
          <b-input v-model="client_company.company_name" type="text" style="width: 300px" />
        </div>
        <div class="form-inline">
          <label>担当者</label>
          <b-form-select v-model="client_company.user_id" style="width: 200px">
            <option v-for="option in sel_user_list" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
      </div>
      <div class="modal-footer">
        <b-button @click="$bvModal.hide('modal-client_company2')">
          <b-icon icon="x"></b-icon>取消
        </b-button>
        <b-button variant="primary" @click="updateClientCompany">
          <b-icon icon="save"></b-icon>&nbsp;保存
        </b-button>
      </div>
    </b-modal>
    <!-- 詳細 モーダル -->
    <MasterClientCompanyDetail ref="child_master_client_company_detail2" @close_modal='closeDetailModal'
      :modal_id="'master_client_company-master_client_company_detail2'" :top_title="'顧客 > 編集'" />
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  // axiosInstance,
  mounted() {
    this.checkLogin()
    this.getClientCompanyList(1);
  },
  data: function () {
    return {
      current_page: 1,
      // table
      busy: false,
      sort_by: '',
      sort_desc: true,
      filter_list: new Array(15),
      filter_val: new Array(15),
      filter_val_ex: new Array(15),
      data_list: [],
      data_count: 0,
      data_list_field: [
        { key: "edit", label: "選択・編集", thStyle: { width: "110px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, select: true, edit2: true },
        { key: "company_code", label: "顧客コード", thStyle: { width: "120px" }, sortable: true, filter: 'input_ex' },
        { key: "company_name", label: "顧客名", thStyle: { width: "200px" }, sortable: true, filter: 'input_ex' },
        { key: "company_name_kana", label: "顧客名(ひらがな)", thStyle: { width: "200px" }, sortable: true, filter: 'input_ex' },
        { key: "zip_code", label: "郵便番号", thStyle: { width: "100px" }, sortable: true, filter: 'input_ex' },
        { key: "address", label: "住所", thStyle: { width: "300px" }, sortable: true, filter: 'input_ex' },
        { key: "user", label: "担当者", thStyle: { width: "" }, sortable: true, filter: 'select_ex2' },
      ],
      //
      sel_user_list: [],
      client_company: [],

      maxHeight: document.documentElement.scrollHeight - 300 + "px"
    }
  },
  methods: {
    //
    onModalHide: function () {
      this.filter_val_ex = new Array(10);
      this.getClientCompanyList();
    },
    //
    closeDetailModal: function () {
      this.getClientCompanyList();
    },
    // 顧客選択
    onSelect: function (id) {
      this.$emit('on_select', id);
    },
    // 顧客一覧 取得
    getClientCompanyList: function () {
      this.busy = false
      axios
        .get('/master_client_company/get_client_company_list', {
          params: {
            select_flg: true,
            company_code_ex: this.filter_val_ex[1],
            company_name_ex: this.filter_val_ex[2], company_name_kana_ex: this.filter_val_ex[3],
            zip_code_ex: this.filter_val_ex[4], address_ex: this.filter_val_ex[5],
            user_id_ex: this.filter_val_ex[6], current_page: this.current_page,
            sort_by: this.sort_by, sort_desc: this.sort_desc
          }
        })
        .then(response => {
          this.data_list = response.data.client_company_list;
          this.data_count = response.data.client_company_count;
          this.filter_list[6] = response.data.user_filter_list_ex;
          this.filter_val_ex[6] = response.data.user_id_ex;
          this.busy = false
        })
    },
    // 顧客 - 会社　詳細
    onDetail: function (client_company_id) {
      this.$refs.child_master_client_company_detail2.setClientCompanyId(client_company_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'master_client_company-master_client_company_detail2');
      }, 100);
    },
    // 顧客 - 会社-モーダル(新規) 表示
    modalShow: function (client_company_id) {
      axios
        .get(`/master_client_company/get_client_company/${client_company_id}`)
        .then(response => {
          this.client_company = response.data.client_company_h;
          this.sel_user_list = response.data.sel_user_list;
          this.$root.$emit('bv::show::modal', 'modal-client_company2')
        })
    },
    // 顧客 - 会社 新規登録
    updateClientCompany: function () {
      axios
        .patch(`/master_client_company/update_client_company`, { client_company: this.client_company })
        .then(response => {
          (this.client_company = response.data);
          this.getClientCompanyList();
          this.$root.$emit('bv::hide::modal', 'modal-client_company2')
        })
    },
    //
    onFilterEx: function () {
      this.getClientCompanyList(1);
    },
    onPageChange: function (current_page) {
      this.current_page = current_page
      this.getClientCompanyList();
    },
    // ソート
    onSort: function (ctx) {
      this.sort_by = ctx.sortBy;
      this.sort_desc = ctx.sortDesc;
      this.getClientCompanyList();
    }
  }
}
</script>
  
<style scoped></style>